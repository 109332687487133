import React from 'react';

const selectedTab = 'bg-primary text-white font-semibold';
function TopNav({ onChange, value }) {
  const setTab = (tab) => {
    if (value !== tab) {
      onChange(tab);
    }
  };

  return (
    <div class="flex items-center justify-around p-2 rounded-lg">
      <button
        class={`px-3 py-1 ${
          value === 'MTD' ? selectedTab : 'text-inactive  bg-white'
        } rounded-lg text-lg`}
        onClick={() => setTab('MTD')}
      >
        Monthly
      </button>
      <button
        class={`${
          value === 'daily' ? selectedTab : 'text-inactive  bg-white'
        } px-3 py-1 rounded-lg text-lg`}
        onClick={() => setTab('daily')}
      >
        Daily
      </button>
    </div>
  );
}

export default TopNav;
